<template>
    <div class="collection">
        <vendor-top :type.sync="type" :searchKey.sync="searchKey" @submitSearch="submitSearch">
            <template slot="button">
                <el-button type="warning" size="small" @click="$router.push('/vendor/newsEdit')">{{$t('xin-zeng-xin-wen')}}</el-button>
            </template>

            <template slot="bottom-left">
                <el-button
                    v-for="(item, index) in typeInfos"
                    :type="type === item.key ? 'warning' : 'info'"
                    size="small"
                    :key="index"
                    plain
                    @click="type = item.key"
                    >{{ item.name }}</el-button
                >
            </template>
        </vendor-top>

        <div class="el-main" v-loading="loading">
            <div class="mainList" ref="list">
                <news-row :info="item" v-for="(item, index) in list" :first="index === 0" :key="item.id"></news-row>
            </div>
            <empty-page v-if="empty">
                <img slot="img" :src="require('@/assets/kong_img_default.png')" />
                <span slot="text">{{$t('zan-shi-mei-you-xin-weno')}}</span>
            </empty-page>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableList from '../../mixins/pageableList';
import vendorTop from '../../components/vendor/Top';
import NewsRow from '../../components/vendor/NewsRow';
import CaseRow from '../../components/vendor/CaseRow';
import { collectType } from '../../utils/AppState';
import { InformationTypeEdit } from '../../utils/AppState';

export default {
    name: 'case',
    mixins: [pageableList],
    data() {
        return {
            url: '/information/my',
            chooseList: [],
            isEdit: false,
            menus: [],
            customCateId: '',
            status: 0,
            noPage: true,
            type: 'INDUSTRY_NEWS',
            types: ['INDUSTRY_NEWS', 'NEW_PRODUCT_RELEASE', 'TECHNICAL_INFORMATION']
        };
    },
    computed: {
        ...mapState(['buyersInfo']),
        listQuery() {
            return {
                vendorId: this.buyersInfo.vendorId,
                type: this.type
            };
        },
        typeInfos() {
            return [...this.types].map(item => {
                return {
                    name: this.$t(InformationTypeEdit.get(item)?.name),
                    key: item
                };
            });
        }
    },
    watch: {
        type() {
            this.getData();
        }
    },
    mounted() {
        if (this.$route.query.type) {
            this.type = this.$route.query.type;
        }
        this.$nextTick(() => {
            this.getData();
        });
    },
    components: {
        vendorTop,
        NewsRow,
        CaseRow
    },
    methods: {
        move(info) {
            const list = [...this.list];
            const start = list.findIndex(item => {
                return info.id === item.id;
            });
            const end = info.direction + start;
            this.loading = true;
            Promise.all([
                this.$http.post(
                    '/successCase/save',
                    {
                        ...list[start],
                        sort: list[end].sort
                    },
                    { body: 'json' }
                ),
                this.$http.post(
                    '/successCase/save',
                    {
                        ...list[end],
                        sort: list[start].sort
                    },
                    { body: 'json' }
                )
            ])
                .then(_ => {
                    this.$message.success(info.direction > 0 ? this.$t('xia-yi-cheng-gong') : this.$t('shang-yi-cheng-gong'));
                    this.getData();
                })
                .catch(e => {
                    console.log(e);
                    this.loading = false;
                    this.$message.error(e.error);
                });
        },
        del(id) {
            this.$alert(this.$t('shan-chu-jiang-wu-fa-hui-fu-que-ren-yao-shan-chu-mo'), this.$t('jing-gao'), { type: 'error' })
                .then(() => {
                    return this.$http.post(`/successCase/del/${id}`);
                })
                .then(() => {
                    this.$message.success(this.$t('shan-chu-cheng-gong'));
                    this.getData();
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error(e.error);
                    }
                });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/pagebleTable';
@import '../../styles/variables';

.route {
    background-color: transparent !important;
}

.el-main {
    margin: 20px 0 0;
    // min-height: calc(100% - 136px);
    overflow: hidden;
    background-color: #fff;
}
</style>
<style lang="scss">
.el-radio-group {
    .el-radio-button__inner {
        border-radius: 0px !important;
    }
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: #ffa526;
        border-color: #ffa526;
        box-shadow: -1px 0 0 0 #ffa526;
    }
}
</style>
